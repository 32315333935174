<template>
  <div class="mb-3 card">
    <div>
      <v-form v-model="valid" ref="form" lazy-validation>
        <v-tabs v-model="activeTab">
          <v-tab key="overall">{{ $t("generic.lang_set_overall") }}</v-tab>
          <v-tab key="ec-terminal">
            {{ $t("settings.lang_nav_ecterminal") }}
          </v-tab>
          <v-tab key="printing">{{ $t("generic.lang_printing") }}</v-tab>

          <v-tabs-items touchless v-model="activeTab">
            <v-tab-item key="overall" eager>
              <v-container>
                <v-row align="center">
                  <v-col cols="12" md="6">
                    <!-- Alias Name -->
                    <v-text-field
                      v-model="name"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      @focus="showTouchKeyboard"
                      :label="$t('generic.lang_name')"
                      autocomplete="off"
                      outlined
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <!-- Location -->
                    <v-text-field
                      v-model="location"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      @focus="showTouchKeyboard"
                      :label="$t('generic.lang_location')"
                      autocomplete="off"
                      outlined
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <!-- KassenID -->
                    <v-select
                      v-model="cashierID"
                      :items="filteredCashierIDs"
                      :label="$t('generic.lang_cashierID')"
                      outlined
                      required
                      :rules="[rules.required]"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" md="6">
                    <!-- Front page Template -->
                    <v-select v-model="frontPageTemplateId" item-value="id" item-text="name" :items="frontPageTemplates" :label="$t('kiosk.lang_frontPageTemplates')"
                      outlined required clearable></v-select>
                  </v-col>

                  <v-col cols="12" md="6">
                    <!-- ip adress of the hybridserver -->
                    <v-text-field
                      v-model="hybridServerIP"
                      @focus="showTouchKeyboard"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      :label="$t('generic.lang_hybridServerIPAddress')"
                      autocomplete="off"
                      outlined
                      required
                      :rules="[rules.required, rules.ipAddress]"
                    />
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field :append-icon="visible ? 'lnr-eye' : 'lnr-eye'"
                                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  :label="$t('generic.lang_password')"
                                  :type="visible ? 'text' : 'password'"
                                  @click:append="visible = !visible"
                                  @focus="showTouchKeyboard"
                                  :rules="[(adminPw?rules.minFour:true)]"
                                  @keypress="acceptOnlyNumbers"
                                  autocomplete="off"
                                  aria-autocomplete="none"
                                  :hint="$t('generic.lang_atLeast') + ' 4 ' + $t('generic.lang_numbers')"
                                  name="newPassword"
                                  outlined v-model="adminPw" value=""/>
                  </v-col>

                  <v-col v-if="$store.getters['permissions/checkModule'](56)" cols="12" class="mb-5">
                    <p>{{ $t('kiosk.lang_selectKioskType') }}</p>
                    <v-btn-toggle v-model="kioskType" active-class="success">
                      <v-btn :value="1" class="ma-0">
                        {{ $t('kiosk.lang_FoodeBeverages') }}
                      </v-btn>
                      <v-btn :value="2" class="ma-0">
                        {{ $t('kiosk.lang_tikcetKiosk') }}
                      </v-btn>
                    </v-btn-toggle>   
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>

            <v-tab-item key="ec-terminal" eager>
              <v-container>
                <v-row>
                  <v-col cols="12" md="6">
                    <!-- ZVT Server IP -->
                    <v-text-field
                      v-model="zvtServerIP"
                      @focus="showTouchKeyboard"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      :label="'ZVT Server ' + this.$t('generic.lang_ipAddress')"
                      autocomplete="off"
                      outlined
                      required
                      :rules="[rules.required, rules.ipAddress]"
                    />
                  </v-col>

                  <v-col cols="12" md="6">
                    <!-- ZVT Server Port -->
                    <v-text-field
                      v-model="zvtServerPort"
                      @focus="showTouchKeyboard"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      :label="$t('generic.lang_zvtServerPort')"
                      autocomplete="off"
                      type="number"
                      min="1"
                      outlined
                      required
                      :rules="[rules.required]"
                    />
                  </v-col>

                  <v-col cols="12" md="6">
                    <!-- Terminal IP -->
                    <v-text-field
                      v-model="terminalIP"
                      @focus="showTouchKeyboard"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      :label="$t('generic.lang_terminalIP')"
                      autocomplete="off"
                      outlined
                      required
                      :rules="[rules.required, rules.ipAddress]"
                    />
                  </v-col>

                  <v-col cols="12" md="6">
                    <!-- Terminal Port -->
                    <v-text-field
                      v-model="terminalPort"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      @focus="showTouchKeyboard"
                      autocomplete="off"
                      :label="$t('generic.lang_terminalPort')"
                      type="number"
                      min="1"
                      outlined
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <!-- Terminal Password -->
                    <v-text-field
                      v-model="terminalPW"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      @focus="showTouchKeyboard"
                      autocomplete="off"
                      :label="$t('generic.lang_terminalPassword')"
                      type="password"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>

            <v-tab-item key="printing" eager>
              <v-container>
                <v-radio-group
                  v-model="externalType"
                  :label="$t('settings.lang_typeOfExternalESCPosPrinter')"
                  row
                >
                  <v-radio
                    :label="$t('settings.lang_network')"
                    value="NETWORK"
                  ></v-radio>
                  <v-radio label="USB" value="USB"></v-radio>
                  <v-radio
                      :label="$t('settings.lang_networkHybrid')"
                      value="NetworkHybrid"
                  ></v-radio>
                </v-radio-group>

                <v-row v-if="externalType === 'USB'">
                  <v-col cols="6">
                    <v-text-field
                      v-model="usbField1"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      @focus="showTouchKeyboard"
                      label="USB HID 1"
                      autocomplete="off"
                      outlined
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                      v-model="usbField2"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      @focus="showTouchKeyboard"
                      label="USB HID 2"
                      autocomplete="off"
                      outlined
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row v-if="externalType === 'NETWORK'">
                  <v-col cols="6">
                    <!-- IP Adresse -->
                    <v-text-field
                      v-model="address"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      @focus="showTouchKeyboard"
                      :label="$t('settings.lang_printerManagerIPAdress')"
                      placeholder="192.168.178.45"
                      autocomplete="off"
                      outlined
                      required
                      :rules="[rules.required, rules.ipAddress]"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="externalType === 'NetworkHybrid'">
                  <v-col cols="6">
                    <!-- IP Adresse -->
                    <v-text-field
                      v-model="address"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      @focus="showTouchKeyboard"
                      :label="$t('settings.lang_printerManagerIPAdress')"
                      placeholder="192.168.178.45"
                      autocomplete="off"
                      outlined
                      required
                      :rules="[rules.required, rules.ipAddress]"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row v-if="externalType !== 'NetworkHybrid'">
                  <v-col cols="6">
                    <!-- Print Server IP Adresse -->
                    <v-text-field
                      v-model="serverAddress"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      @focus="showTouchKeyboard"
                      autocomplete="off"
                      :label="$t('settings.lang_printServerIPAddress')"
                      placeholder="127.0.0.1"
                      outlined
                      required
                      :rules="[rules.required, rules.ipAddress]"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <!-- Print Server Port -->
                    <v-text-field
                      v-model="serverPort"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      @focus="showTouchKeyboard"
                      autocomplete="off"
                      :label="$t('generic.lang_printServerPort')"
                      :hint="$t('generic.lang_defaultPort') + ': 3089'"
                      type="number"
                      min="1"
                      outlined
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>

        <v-container
          fluid
          :style="this.$vuetify.theme.dark ? 'background-color: #1E1E1E;' : ''"
        >
          <v-layout>
            <v-flex class="text-right">
              <v-btn
                :disabled="this.loading"
                @click="goBack"
                color="error"
                text
              >
                {{ $t("generic.lang_prev") }}
              </v-btn>

              <v-btn
                :disabled="this.loading"
                :loading="this.loading"
                @click="createTerminal"
                color="success"
              >
                {{ $t("generic.lang_add") }}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>
    </div>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard
        v-if="touchKeyboard.visible"
        id="onScreenKeyboard"
        :accept="hideTouchKeyboard"
        :cancel="hideTouchKeyboard"
        :defaultKeySet="touchKeyboard.keySet"
        :input="touchKeyboard.input"
        :layout="touchKeyboard.layout"
        :options="touchKeyboard.options"
        class="internalWidth"
      />
    </div>
  </div>
</template>

<script>
import mixin from "@/mixins/KeyboardMixIns";
import validation from "../../../mixins/validation/validation";
import { mapState } from "vuex";
import { ENDPOINTS } from "@/config";
import { Events } from "@/plugins/events";
import sha512 from "js-sha512";

export default {
  name: "CreateTerminalComponent",

  mixins: [mixin,validation],

  data() {
    return {
      valid: true,
      activeTab: 0,
      loading: false,
      visible: false,
      configToken: null,
      name: "",
      location: "",
      frontPageTemplateId: null,
      frontPageTemplates: [],
      cashierID: null,
      hybridServerIP: "192.168.178.0",
      zvtServerIP: "127.0.0.1",
      zvtServerPort: 3000,
      terminalIP: "127.0.0.1",
      terminalPort: 3000,
      terminalPW: null,
      externalType: "NETWORK",
      usbField1: null,
      usbField2: null,
      address: "192.168.178.0",
      serverAddress: "127.0.0.1",
      serverPort: 3089,
      cashierIDs: [],
      adminPw:null,
      kioskType: 1,
    };
  },
  computed: {
    ...mapState(["api"]),
    filteredCashierIDs() {
      return this.cashierIDs.map((cashierID) => {
        return {
          value: parseInt(cashierID),
          text: this.$t("generic.lang_kasse") + " " + cashierID,
        };
      });
    },
  },

  mounted() {
    this.cashierID = this.api.auth.cashierID;
    this.getCashiers();
    this.getTemplates();
  },

  methods: {
    goBack() {
      this.$router.push("/kiosk/terminals");
    },
    getTemplates() {
      this.loading = true;
      this.axios.post(ENDPOINTS.KIOSK.SETTINGS.DESIGN.FRONTPAGE.TEMPLATE.GETALL)
        .then(res => {
          if (res.data.success) {
            this.frontPageTemplates = Object.values(res.data.data);
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_errorOccurred'),
              color: "error"
            });
          }
        }).catch(err => {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
            color: "error"
          });
        }).finally(() => {
          this.loading = false;
        })
    },
    getCashiers() {
      this.loading = true;

      this.axios
        .post(ENDPOINTS.GENERIC.CASHIERIDS.GET)
        .then((res) => {
          this.cashierIDs = res.data.cashierIDs;
        })
        .finally(() => (this.loading = false));
    },
    createTerminal() {
      if (!this.$refs.form.validate()) return;

      this.loading = true;

      this.axios
        .post(ENDPOINTS.KIOSK.TERMINALS.CREATE, {
          name: this.name,
          location: this.location,
          cashierID: this.cashierID,
          hybridServerIP: this.hybridServerIP,
          zvtServerIP: this.zvtServerIP,
          zvtServerPort: this.zvtServerPort,
          terminalIP: this.terminalIP,
          terminalPort: this.terminalPort,
          terminalPW: this.terminalPW,
          externalType: this.externalType,
          usbField1: this.usbField1,
          usbField2: this.usbField2,
          address: this.address,
          serverAddress: this.serverAddress,
          serverPort: this.serverPort,
          frontPageTemplateId: this.frontPageTemplateId,
          adminPw:this.adminPw?sha512(this.adminPw):'',
          kioskType: this.kioskType,
        })
        .then((res) => {
          if (res.data.status === "SUCCESS") {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_success"),
              color: "success",
            });

            this.goBack();
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .catch(() => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred"),
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
